// context.js
import type { Query } from 'database/bigQueryHelper';
// import type { FilterAttributes } from '@database/queryHelper';
import pickBy from 'lodash/pickBy';
import React, { createContext, useContext, useState } from 'react';

type Filter = Record<
  string,
  string | number | boolean | number[] | string[] | boolean[] | null
>;

export type ContextData = {
  queries: Record<string, Query>;
  filters: Filter[];
  keyfigures?: Record<string, (string | number)[][]>;
};

const defaultContextData: ContextData = { queries: {}, filters: [] };

// Create a context
const ExcelContext = createContext({
  data: defaultContextData,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setData: (_prev: ContextData) => {},
});

// Create a custom Provider component
export function ExcelDataProvider({ children }: { children: React.ReactNode }) {
  const [data, setData] = useState<ContextData>(defaultContextData);

  return (
    <ExcelContext.Provider value={{ data, setData }}>
      {children}
    </ExcelContext.Provider>
  );
}

// Custom hook to access the context
export function useExportExcelContext() {
  return useContext(ExcelContext);
}

// Custom hook to remove unused data from context, to avoir surcharging the page with useless data
export function lighterContext(context: ContextData) {
  const { queries, filters: filtersList } = context;

  // queries to export
  const _q = pickBy(queries, (_v, _k) => _k.startsWith('kpi_'));

  // local function to replace values by labels
  const _labelfn = (
    values: number[] | string[] | boolean[] | null,
    ref: string,
    refIdField: number | string,
    refLabelField: string
  ) => {
    return !values
      ? null
      : (values.map((v) => {
          const _element = queries[ref]?.returnedRows?.find(
            (row) => row[refIdField] === v
          );
          return _element ? _element[refLabelField] : '';
        }) as
          | string
          | number
          | boolean
          | number[]
          | string[]
          | boolean[]
          | null);
  };

  // filters to export
  const _f = filtersList.map((filter) => {
    return {
      ...filter,
      value:
        typeof filter.value === 'object' &&
        typeof filter.ref === 'string' &&
        (typeof filter.id === 'number' || typeof filter.id === 'string') &&
        typeof filter.label === 'string'
          ? _labelfn(filter.value, filter.ref, filter.id, filter.label)
          : filter.value!,
    };
  });

  return {
    queries: _q,
    filters: _f,
    keyfigures: context.keyfigures,
  };
}
