// Types Imports
import type { NavItem } from '@/types/include';

export const navigation: NavItem<string>[] = [
  {
    label: 'Accueil',
    path: '/',
    description: 'Accueil',
    roles: ['data_analyst', 'admin_client', 'atlas_interne', 'orga_pro'],
    icon: 'home.svg',
  },
  {
    label: 'Entreprises',
    path: '/entreprises',
    description: "Vision d'ensemble",
    roles: ['data_analyst', 'admin_client', 'atlas_interne', 'orga_pro'],
    filters: [
      'num_secteur_atlas',
      'num_branche_pro',
      'num_region',
      'code_departement',
    ],
  },
  {
    label: 'Salariés',
    path: '/salaries',
    description: 'Salariés',
    roles: ['data_analyst', 'admin_client', 'atlas_interne', 'orga_pro'],
    filters: [
      'num_secteur_atlas',
      'num_branche_pro',
      'num_region',
      'code_departement',
    ],
  },
  {
    label: 'Recrutement',
    path: '/recrutement',
    description: 'Recrutement',
    roles: ['data_analyst', 'admin_client', 'atlas_interne', 'orga_pro'],
    filters: [
      'num_secteur_atlas',
      'num_branche_pro',
      'num_region',
      'code_departement',
    ],
  },
  {
    label: 'Formation',
    path: '/formation',
    description: 'Formation',
    roles: ['data_analyst', 'admin_client', 'atlas_interne', 'orga_pro'],
    subItems: [
      {
        label: 'En synthèse',
        path: '/formation/synthese',
        description: 'KPI',
        roles: ['data_analyst', 'admin_client', 'atlas_interne', 'orga_pro'],
        filters: [
          // 'code_secteur_naf732',
          // 'num_branche_pro',
          // 'num_region',
          // 'code_departement',
        ],
      },
      {
        label: 'Alternance 🔻',
        path: '/formation/alternance',
        description: 'KPI',
        roles: ['data_analyst', 'admin_client', 'atlas_interne', 'orga_pro'],
        subItems: [
          {
            label: 'Chiffres clés',
            path: '/formation/alternance/chiffres-cles',
            description: 'KPI',
            roles: [
              'data_analyst',
              'admin_client',
              'atlas_interne',
              'orga_pro',
            ],
            filters: [
              'num_secteur_atlas',
              'num_branche_pro',
              'num_region',
              'code_departement',
            ],
          },
          {
            label: 'Profil des alternants',
            path: '/formation/alternance/profil-des-alternants',
            description: 'KPI',
            roles: [
              'data_analyst',
              'admin_client',
              'atlas_interne',
              'orga_pro',
            ],
            filters: [
              'num_secteur_atlas',
              'num_branche_pro',
              'num_region',
              'code_departement',
            ],
          },
          {
            label: 'Profil des entreprises',
            path: '/formation/alternance/profil-des-entreprises',
            description: 'KPI',
            roles: [
              'data_analyst',
              'admin_client',
              'atlas_interne',
              'orga_pro',
            ],
            filters: [
              'num_secteur_atlas',
              'num_branche_pro',
              'num_region',
              'code_departement',
            ],
          },
          {
            label: 'Montants engagés',
            path: '/formation/alternance/montants-engages',
            description: 'KPI',
            roles: [
              'data_analyst',
              'admin_client',
              'atlas_interne',
              'orga_pro',
            ],
            filters: [
              'num_secteur_atlas',
              'num_branche_pro',
              'num_region',
              'code_departement',
            ],
          },
          {
            label: 'Formations suivies',
            path: '/formation/alternance/formations-suivies',
            description: 'KPI',
            roles: [
              'data_analyst',
              'admin_client',
              'atlas_interne',
              'orga_pro',
            ],
            filters: [
              'num_secteur_atlas',
              'num_branche_pro',
              'num_region',
              'code_departement',
            ],
          },
        ],
      },
      {
        label: 'Formation continue 🔻',
        path: '/formation/fcont',
        description: 'KPI',
        roles: ['data_analyst', 'admin_client', 'atlas_interne', 'orga_pro'],
        subItems: [
          {
            label: 'Chiffres clés',
            path: '/formation/fcont/chiffres-cles',
            description: 'KPI',
            roles: [
              'data_analyst',
              'admin_client',
              'atlas_interne',
              'orga_pro',
            ],
            filters: [
              'num_secteur_atlas',
              'num_branche_pro',
              'num_region',
              'code_departement',
            ],
          },
          {
            label: 'Profil des stagiaires',
            path: '/formation/fcont/profil-des-stagiaires',
            description: 'KPI',
            roles: [
              'data_analyst',
              'admin_client',
              'atlas_interne',
              'orga_pro',
            ],
            filters: [
              'num_secteur_atlas',
              'num_branche_pro',
              'num_region',
              'code_departement',
            ],
          },
          {
            label: 'Profil des entreprises',
            path: '/formation/fcont/profil-des-entreprises',
            description: 'KPI',
            roles: [
              'data_analyst',
              'admin_client',
              'atlas_interne',
              'orga_pro',
            ],
            filters: [
              'num_secteur_atlas',
              'num_branche_pro',
              'num_region',
              'code_departement',
            ],
          },
          {
            label: 'Montants engagés',
            path: '/formation/fcont/montants-engages',
            description: 'KPI',
            roles: [
              'data_analyst',
              'admin_client',
              'atlas_interne',
              'orga_pro',
            ],
            filters: [
              'num_secteur_atlas',
              'num_branche_pro',
              'num_region',
              'code_departement',
            ],
          },
          {
            label: 'Formations suivies',
            path: '/formation/fcont/formations-suivies',
            description: 'KPI',
            roles: [
              'data_analyst',
              'admin_client',
              'atlas_interne',
              'orga_pro',
            ],
            filters: [
              'num_secteur_atlas',
              'num_branche_pro',
              'num_region',
              'code_departement',
            ],
          },
        ],
      },
      {
        label: 'POE 🔻',
        path: '/formation/poe',
        description: 'KPI',
        roles: ['data_analyst', 'admin_client', 'atlas_interne', 'orga_pro'],
        subItems: [
          {
            label: 'Chiffres clés',
            path: '/formation/poe/chiffres-cles',
            description: 'KPI',
            roles: [
              'data_analyst',
              'admin_client',
              'atlas_interne',
              'orga_pro',
            ],
            filters: [
              'num_secteur_atlas',
              'num_branche_pro',
              'num_region',
              'code_departement',
            ],
          },
          {
            label: 'Profil des stagiaires',
            path: '/formation/poe/profil-des-stagiaires',
            description: 'KPI',
            roles: [
              'data_analyst',
              'admin_client',
              'atlas_interne',
              'orga_pro',
            ],
            filters: [
              'num_secteur_atlas',
              'num_branche_pro',
              'num_region',
              'code_departement',
            ],
          },
          {
            label: 'Montants engagés',
            path: '/formation/poe/montants-engages',
            description: 'KPI',
            roles: [
              'data_analyst',
              'admin_client',
              'atlas_interne',
              'orga_pro',
            ],
            filters: [
              'num_secteur_atlas',
              'num_branche_pro',
              'num_region',
              'code_departement',
            ],
          },
          {
            label: 'Formations suivies',
            path: '/formation/poe/formations-suivies',
            description: 'KPI',
            roles: [
              'data_analyst',
              'admin_client',
              'atlas_interne',
              'orga_pro',
            ],
            filters: [
              'num_secteur_atlas',
              'num_branche_pro',
              'num_region',
              'code_departement',
            ],
          },
        ],
      },
      {
        label: 'Prestations RH 🔻',
        path: '/formation/prestations-rh',
        description: 'KPI',
        roles: ['data_analyst', 'admin_client', 'atlas_interne', 'orga_pro'],
        subItems: [
          {
            label: 'Chiffres clés',
            path: '/formation/prestations-rh/chiffres-cles',
            description: 'KPI',
            roles: [
              'data_analyst',
              'admin_client',
              'atlas_interne',
              'orga_pro',
            ],
            filters: [
              'num_secteur_atlas',
              'num_branche_pro',
              'num_region',
              'code_departement',
            ],
          },
          {
            label: 'Profil des entreprises',
            path: '/formation/prestations-rh/profil-des-entreprises',
            description: 'KPI',
            roles: [
              'data_analyst',
              'admin_client',
              'atlas_interne',
              'orga_pro',
            ],
            filters: [
              'num_secteur_atlas',
              'num_branche_pro',
              'num_region',
              'code_departement',
            ],
          },
          {
            label: 'Montants engagés',
            path: '/formation/prestations-rh/montants-engages',
            description: 'KPI',
            roles: [
              'data_analyst',
              'admin_client',
              'atlas_interne',
              'orga_pro',
            ],
            filters: [
              'num_secteur_atlas',
              'num_branche_pro',
              'num_region',
              'code_departement',
            ],
          },
        ],
      },
    ],
  },
  // {
  //   label: 'Baromètre des prix',
  //   path: '/barometre-des-prix',
  //   description: 'Baromètre des prix',
  //   roles: [
  //     'data_analyst',
  //     'admin_client',
  //     'atlas_interne',
  //     'orga_pro',
  //     'atlas_externe',
  //   ],
  // },
  // {
  //   label: 'Recherche de formations',
  //   path: '/recherche-de-formations',
  //   description: 'Moteur de recherche',
  //   roles: ['data_analyst', 'admin_client', 'atlas_interne', 'orga_pro'],
  //   filters: [],
  // },
];
